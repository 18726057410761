import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query abtQuery {
      img0: file(relativePath: { eq: "30years.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "hotairballoon30years.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1cn: file(relativePath: { eq: "hotairballoon30years_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1en: file(relativePath: { eq: "hotairballoon30years_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.about" description="seo.desc.main" />
      <div className="website-banner">
        <Img className="mx-auto" fluid={data.img0.childImageSharp.fluid} />
      </div>
      <div className="content-master">
        <Container>
          {/*
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.about.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="text-dark">
                <div>{tt("pages.about.desc1")}</div>
              </div>
            </Col>
          </Row>
          */}
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                {tt("pages.about.desc1")}
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              {locale === "zh-hk" && (
                <Img
                  className="rounded mx-auto mt-4 d-block pb-3"
                  fluid={data.img1.childImageSharp.fluid}
                />
              )}
              {locale === "zh-cn" && (
                <Img
                  className="rounded mx-auto mt-4 d-block pb-3"
                  fluid={data.img1cn.childImageSharp.fluid}
                />
              )}
              {locale === "en" && (
                <Img
                  className="rounded mx-auto mt-4 d-block pb-3"
                  fluid={data.img1en.childImageSharp.fluid}
                />
              )}
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <h3 className="font-weight-bold">{tt("pages.about.title4")}</h3>
                <span>
                  <b>{tt("pages.about.title5")}</b>
                  {tt("pages.about.desc2")}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
